@import 'react-dates/lib/css/_datepicker.css';

.CalendarDay {
  position: relative;
  padding: 0;
  border: none;
  font-weight: 500;
  &:hover {
    border: none;
    color: $gray-700;
  }
  & .CurrentDay {
    position: absolute;
    bottom: 0;
    right: 0;
    border-top: 0 solid transparent;
    border-right: 0 solid transparent;
    border-bottom: 10px solid $danger;
    border-left: 10px solid transparent;
  }
}
.CalendarDay__selected_span:active,
.CalendarDay__selected_span {
  border: none;
  background: $primary-light;
  color: $gray-700;
  &:hover {
    background: #d6dde5;
  }
}
.CalendarDay__selected:hover,
.CalendarDay__selected:active,
.CalendarDay__selected {
  border: none;
  background: $primary;
  color: white;
  border-radius: 6px;
}
.CalendarDay__outside {
  color: $gray-500;
}
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: $primary-light;
  color: $gray-700;
}
.DayPicker_transitionContainer {
  box-sizing: content-box;
}
.DayPicker_weekHeader {
  top: 58px;
}
.DayPicker_weekHeader_ul > .DayPicker_weekHeader_li:first-child > * {
  color: $danger !important ;
}
.CalendarMonth_caption {
  padding: 30px 0 52px 0;
  font-size: 13px;
  font-weight: 600;
  color: $gray-800;
}
.CalendarDay__selected_start {
  border-radius: 6px 0 0 6px;
}
.CalendarDay__selected_end {
  border-radius: 0 6px 6px 0;
}
.CalendarDay__selected_start.CalendarDay__selected_end {
  border-radius: 6px;
}
