.react-select-container {
  .react-select__control {
    min-height: 40px;
    border-color: $gray-300;
    border-radius: 8px;
    &:hover {
      border-color: $gray-300;
    }
    .react-select__value-container {
      max-height: 200px;
      overflow: scroll;
    }
    .react-select__multi-value {
      height: 24px;
      align-items: center;
      margin-right: 4px;
      margin-bottom: 4px;
      border-radius: 8px;
      background-color: $gray-200;
      overflow: hidden;
      .react-select__multi-value__label {
        font-weight: 600;
        font-size: 12px;
        color: $gray-600;
        background-color: $gray-200;
      }
      .react-select__multi-value__remove {
        height: 24px;
        width: 24px;
        color: $gray-600;
        background-color: $gray-200;
        &:hover {
          background-color: none;
        }
      }
    }
    .react-select__single-value {
      font-size: 12px;
      font-weight: 600;
      color: $gray-600;
    }
    .react-select__placeholder {
      color: $gray-500;
      font-size: 12px;
      font-weight: 600;
    }
  }
  .react-select__option {
    height: 44px;
    margin-top: 12px;
    margin-bottom: 12px;
    font-size: 14px;
    font-weight: 500;
    color: $gray-600;
    background-color: transparent;
    border-radius: 8px;
    &:hover {
      color: $primary;
    }
    &.react-select__option--is-disabled {
      opacity: 0.5;
      &:hover {
        color: $gray-600;
      }
    }
  }
  .react-select__menu {
    overflow: hidden;
    border-radius: 8px;
    .react-select__menu-list {
      padding-right: 16px;
      padding-left: 16px;
      .react-select__option {
        display: flex;
        align-items: center;
        justify-content: start;
      }
      .react-select__option.react-select__option--is-selected,
      .react-select__option.react-select__option--is-focused {
        background-color: $primary-light;
        color: $primary;
      }
    }
  }
}
