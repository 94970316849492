//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Bootstrap color system
$white: #ffffff;

// Theme colors
// Primary
$primary: #2459ad;
$primary-active: #2b6bd1;
$primary-light: #eef6ff;
$primary-light-dark: #212e48;
$primary-inverse: $white;

// Success
$success: #50cd89;
$success-active: #47be7d;
$success-light: #e8fff3;
$success-light-dark: #1c3238;
$success-inverse: $white;

// Info
$info: #7239ea;
$info-active: #5014d0;
$info-light: #f8f5ff;
$info-light-dark: #2f264f;
$info-inverse: $white;

// Danger
$danger: #f1416c;
$danger-active: #d9214e;
$danger-light: #fff5f8;
$danger-light-dark: #3a2434;
$danger-inverse: $white;

// Warning
$warning: #ffc700;
$warning-active: #f1bc00;
$warning-light: #fff8dd;
$warning-light-dark: #392f28;
$warning-inverse: $white;

// Hight and width sizes
$custom-sizes: (
  unset: unset,
  auto: auto,
  0: 0,
  25: 25%,
  50: 50%,
  75: 75%,
  100: 100%,
  1px: 1px,
  4px: 4px,
  8px: 8px,
  12px: 12px,
  16px: 16px,
  20px: 20px,
  24px: 24px,
  28px: 28px,
  32px: 32px,
  36px: 36px,
  40px: 40px,
  44px: 44px,
  48px: 48px,
  52px: 52px,
  56px: 56px,
  60px: 60px,
  64px: 64px,
  68px: 68px,
  72px: 72px,
  76px: 76px,
  80px: 80px,
  84px: 84px,
  88px: 88px,
  92px: 92px,
  96px: 96px,
  100px: 100px,
  104px: 104px,
  108px: 108px,
  112px: 112px,
  116px: 116px,
  120px: 120px,
  124px: 124px,
  128px: 128px,
  132px: 132px,
  136px: 136px,
  140px: 140px,
  144px: 144px,
  148px: 148px,
  152px: 152px,
  156px: 156px,
  160px: 160px,
  164px: 164px,
  168px: 168px,
  172px: 172px,
  176px: 176px,
  180px: 180px,
  184px: 184px,
  188px: 188px,
  192px: 192px,
  196px: 196px,
  200px: 200px,
  204px: 204px,
  208px: 208px,
  212px: 212px,
  216px: 216px,
  220px: 220px,
  224px: 224px,
  228px: 228px,
  232px: 232px,
  236px: 236px,
  240px: 240px,
  244px: 244px,
  248px: 248px,
  252px: 252px,
  256px: 256px,
  260px: 260px,
  264px: 264px,
  268px: 268px,
  272px: 272px,
  276px: 276px,
  280px: 280px,
  284px: 284px,
  288px: 288px,
  292px: 292px,
  296px: 296px,
  300px: 300px,
  304px: 304px,
  308px: 308px,
  312px: 312px,
  316px: 316px,
  320px: 320px,
  324px: 324px,
  328px: 328px,
  332px: 332px,
  336px: 336px,
  340px: 340px,
  344px: 344px,
  348px: 348px,
  352px: 352px,
  356px: 356px,
  360px: 360px,
  364px: 364px,
  368px: 368px,
  372px: 372px,
  376px: 376px,
  380px: 380px,
  384px: 384px,
  388px: 388px,
  392px: 392px,
  396px: 396px,
  400px: 400px,
  404px: 404px,
  408px: 408px,
  412px: 412px,
  416px: 416px,
  420px: 420px,
  424px: 424px,
  428px: 428px,
  432px: 432px,
  436px: 436px,
  440px: 440px,
  444px: 444px,
  448px: 448px,
  452px: 452px,
  456px: 456px,
  460px: 460px,
  464px: 464px,
  468px: 468px,
  472px: 472px,
  476px: 476px,
  480px: 480px,
  484px: 484px,
  488px: 488px,
  492px: 492px,
  496px: 496px,
  500px: 500px,
  504px: 504px,
  508px: 508px,
  512px: 512px,
  516px: 516px,
  520px: 520px,
  524px: 524px,
  528px: 528px,
  532px: 532px,
  536px: 536px,
  540px: 540px,
  544px: 544px,
  548px: 548px,
  552px: 552px,
  556px: 556px,
  560px: 560px,
  564px: 564px,
  568px: 568px,
  572px: 572px,
  576px: 576px,
  580px: 580px,
  584px: 584px,
  588px: 588px,
  592px: 592px,
  596px: 596px,
  600px: 600px,
  604px: 604px,
  608px: 608px,
  612px: 612px,
  616px: 616px,
  620px: 620px,
  624px: 624px,
  628px: 628px,
  632px: 632px,
  636px: 636px,
  640px: 640px,
  644px: 644px,
  648px: 648px,
  652px: 652px,
  656px: 656px,
  660px: 660px,
  664px: 664px,
  668px: 668px,
  672px: 672px,
  676px: 676px,
  680px: 680px,
  684px: 684px,
  688px: 688px,
  692px: 692px,
  696px: 696px,
  700px: 700px,
  704px: 704px,
  708px: 708px,
  712px: 712px,
  716px: 716px,
  720px: 720px,
  724px: 724px,
  728px: 728px,
  732px: 732px,
  736px: 736px,
  740px: 740px,
  744px: 744px,
  748px: 748px,
  752px: 752px,
  756px: 756px,
  760px: 760px,
  764px: 764px,
  768px: 768px,
  772px: 772px,
  776px: 776px,
  780px: 780px,
  784px: 784px,
  788px: 788px,
  792px: 792px,
  796px: 796px,
  800px: 800px,
  804px: 804px,
  808px: 808px,
  812px: 812px,
  816px: 816px,
  820px: 820px,
  824px: 824px,
  828px: 828px,
  832px: 832px,
  836px: 836px,
  840px: 840px,
  844px: 844px,
  848px: 848px,
  852px: 852px,
  856px: 856px,
  860px: 860px,
  864px: 864px,
  868px: 868px,
  872px: 872px,
  876px: 876px,
  880px: 880px,
  884px: 884px,
  888px: 888px,
  892px: 892px,
  896px: 896px,
  900px: 900px,
  904px: 904px,
  908px: 908px,
  912px: 912px,
  916px: 916px,
  920px: 920px,
  924px: 924px,
  928px: 928px,
  932px: 932px,
  936px: 936px,
  940px: 940px,
  944px: 944px,
  948px: 948px,
  952px: 952px,
  956px: 956px,
  960px: 960px,
  964px: 964px,
  968px: 968px,
  972px: 972px,
  976px: 976px,
  980px: 980px,
  984px: 984px,
  988px: 988px,
  992px: 992px,
  996px: 996px,
  1000px: 1000px,
) !default;

$symbol-sizes: (
  default: 52px,
  4px: 4px,
  8px: 8px,
  12px: 12px,
  16px: 16px,
  20px: 20px,
  24px: 24px,
  28px: 28px,
  32px: 32px,
  36px: 36px,
  40px: 40px,
  44px: 44px,
  48px: 48px,
  52px: 52px,
  56px: 56px,
  60px: 60px,
  64px: 64px,
  68px: 68px,
  72px: 72px,
  76px: 76px,
  80px: 80px,
  84px: 84px,
  88px: 88px,
  92px: 92px,
  96px: 96px,
  100px: 100px,
  104px: 104px,
  108px: 108px,
  112px: 112px,
  116px: 116px,
  120px: 120px,
  124px: 124px,
  128px: 128px,
  132px: 132px,
  136px: 136px,
  140px: 140px,
  144px: 144px,
  148px: 148px,
  152px: 152px,
  156px: 156px,
  160px: 160px,
  164px: 164px,
  168px: 168px,
  172px: 172px,
  176px: 176px,
  180px: 180px,
  184px: 184px,
  188px: 188px,
  192px: 192px,
  196px: 196px,
  200px: 200px,
) !default;

// Spacing
$spacer: 16px !default;

$spacers: (
  0: 0,
  1: (
    $spacer * 0.25,
  ),
  // 4px
  2:
    (
      $spacer * 0.5,
    ),
  // 8px;
  3:
    (
      $spacer * 0.75,
    ),
  // 12px
  4:
    (
      $spacer * 1,
    ),
  // 16px
  5:
    (
      $spacer * 1.25,
    ),
  // 20px
  6:
    (
      $spacer * 1.5,
    ),
  // 24px
  7:
    (
      $spacer * 1.75,
    ),
  // 28px
  8:
    (
      $spacer * 2,
    ),
  // 32px
  9:
    (
      $spacer * 2.25,
    ),
  // 36px
  10:
    (
      $spacer * 2.5,
    ),
  // 40px
  11:
    (
      $spacer * 2.75,
    ),
  // 44px
  12:
    (
      $spacer * 3,
    ),
  // 48px
  13:
    (
      $spacer * 3.25,
    ),
  // 52px
  14:
    (
      $spacer * 3.5,
    ),
  // 56px
  15:
    (
      $spacer * 3.75,
    ),
  // 60px
  16:
    (
      $spacer * 4,
    ),
  // 64px
  17:
    (
      $spacer * 4.25,
    ),
  // 68px
  18:
    (
      $spacer * 4.5,
    ),
  // 72px
  19:
    (
      $spacer * 4.75,
    ),
  // 76px
  20:
    (
      $spacer * 5,
    ),
  // 80px
);

$gutters: (
  0: 0rem,
  1: (
    $spacer * 0.25,
  ),
  // 4px
  2:
    (
      $spacer * 0.5,
    ),
  // 8px;
  3:
    (
      $spacer * 0.75,
    ),
  // 12px
  4:
    (
      $spacer * 1,
    ),
  // 16px
  5:
    (
      $spacer * 1.25,
    ),
  // 20px
  6:
    (
      $spacer * 1.5,
    ),
  // 24px
  7:
    (
      $spacer * 1.75,
    ),
  // 28px
  8:
    (
      $spacer * 2,
    ),
  // 32px
  9:
    (
      $spacer * 2.25,
    ),
  // 36px
  10:
    (
      $spacer * 2.5,
    ),
  // 40px
) !default;

// Border Radiues
$border-radius-sm: 4px !default;
$border-radius: 8px !default;
$border-radius-lg: 12px !default;
$border-radius-xl: 16px !default;
$border-radius-2xl: 20px !default;
$border-radius-pill: 24px !default;

// Line heights
$line-height-base: 1 !default;
$line-height-sm: 1.25 !default;
$line-height-lg: 1.5 !default;
$line-height-xl: 1.75 !default;
$line-height-xxl: 2 !default;

// Form checks
$form-check-input-width: 28px !default;
$form-check-input-width-sm: 28px !default;
$form-check-input-width-lg: 28px !default;
$form-check-padding-start: $form-check-input-width + 12px !default;
$form-check-padding-left: $form-check-input-width + 12px !default;

// Tables
$table-cell-padding-y: 0 !default;
$table-cell-padding-x: 12px !default;
$table-cell-padding-y-sm: 0 !default;
$table-cell-padding-x-sm: 12px !default;

// Card
$card-px: 32px !default;
