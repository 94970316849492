//
// Keenicons
//

.ki-duotone,
.ki-outline,
.ki-solid {
  line-height: 1;
  font-size: 1rem;
  color: inherit;
}
