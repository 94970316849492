.tags-input__control {
  font-size: 12px;
  &::placeholder {
    font-weight: 600;
    font-size: 12px;
    color: $gray-500;
  }
}
.tags-input__option {
  color: $gray-600;
  &:hover,
  &.tags-input__option--is-selected {
    background-color: $primary-light;
    color: $primary;
  }
}
